import React from 'react';
import { FAQ } from 'components/Solucoes';

const data = [
  {
    question: `A Nexodata Atrium integra somente sistemas de farmácias (lojas) físicas?`,
    answer: (
      <p>
        Não, Nexodata Atrium é uma API de receitas focada no omnichannel, que
        viabiliza a operação em qualquer canal, seja ela em E-Commerce,
        Teleatendimento e lojas físicas, WhatsApp ou seu aplicativo de maneira
        segura e fácil. Nossa API foi desenvolvida dentro dos melhores padrões e
        boas práticas de mercado, para entregar ao seu sistema todas informações
        necessárias para obter a receita digital e validar as exigências dos
        órgãos reguladores, como por exemplo a validação da assinatura digital e
        do CRM do prescritor.
      </p>
    )
  },
  {
    question: `O que meu sistema precisa ter para conectar com a Atrium?`,
    answer: (
      <p>
        Basta seu sistema consumir a Atrium API, que é uma API RESTFul, com
        dados sendo transportados via JSON. São endpoints claros e objetivos que
        vão dar todas informações necessárias para seu sistema receber as
        prescrições eletrônicas de forma segura.
      </p>
    )
  },
  {
    question: `Gostaria de receber prescrições eletrônicas nos meus canais, com quem eu falo?`,
    answer: (
      <p>
        Possuímos uma equipe técnica pronta para desenvolver a solução junto com
        seu time. Entre em contato com a nossa equipe através do
        parceiros@nexodata.com.br e faremos isso juntos!
      </p>
    )
  }
];

export default function AtriumFAQ() {
  return <FAQ data={data} />;
}
