import styled from 'styled-components';
import { px2rem } from 'src/util';
import { Text, Title, LoadImage, SingleInputForm } from 'components';

export const Wrapper = styled.section`
  background-color: #f1f2f5;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 998px) {
    height: 0;
    padding-bottom: ${(1079 / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    padding: 77px 20px 375px;
  }
`;

export const BackgroundImage = styled(LoadImage)<any>`
  bottom: 0;
  position: absolute;

  @media screen and (min-width: 998px) {
    height: 100%;
    left: 0;
    opacity: 0;
    transform: translateX(5%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;

    &.isVisible {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 997px) {
    height: 425px;
    left: -350px;
  }
`;

export const Grid = styled.div`
  @media screen and (min-width: 998px) {
    align-items: center;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
  }

  @media screen and (max-width: 997px) {
  }
`;

export const Content = styled.div`
  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 8;
  }

  @media screen and (min-width: 998px) {
    ${Title} {
      margin-bottom: ${px2rem(24)};
      max-width: ${px2rem(584)};
    }

    ${Text} {
      margin-bottom: ${px2rem(30)};
      max-width: ${px2rem(530)};
    }
  }

  @media screen and (max-width: 997px) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    ${Title}, ${Text} {
      margin-bottom: ${px2rem(24)};
    }

    ${Title} {
      font-size: 32px;
    }
  }
`;

export const StyledSingleInputForm = styled(SingleInputForm)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(52)};
    width: ${px2rem(520)};
  }

  @media screen and (max-width: 997px) {
    align-self: center;
  }
`;
