import React from 'react';
import { Contact, SEO } from 'components';
import { HowItWorks } from 'components/Solucoes';
import {
  AtriumTopBanner,
  CustomerLoyalty,
  AtriumFAQ
} from 'components/Solucoes/Atrium';

export default function Atrium() {
  return (
    <>
      <SEO title="Nexodata" />
      <AtriumTopBanner />
      <CustomerLoyalty />
      <HowItWorks />
      <AtriumFAQ />
      <Contact
        subtitle="Nosso time de especialistas técnicos pode te ajudar agora."
        type="atrium"
      />
    </>
  );
}
