import React, { useState, useCallback } from 'react';
import { FormikHelpers } from 'formik';
import classNames from 'classnames';
import { CustomVisibilitySensor, Text, Title } from 'components';
import { StyledSubtitle } from 'components/Solucoes/styles';
import {
  Wrapper,
  BackgroundImage,
  Grid,
  Content,
  StyledSingleInputForm
} from './styles';

export default function CustomerLoyalty() {
  return (
    <CustomVisibilitySensor offset={{ bottom: -300, top: -300 }}>
      {({ isVisible }) => (
        <Wrapper>
          <BackgroundImage
            alt=""
            className={classNames({ isVisible })}
            src={require('assets/loyalty-bg.jpg')}
          />

          <Grid className="grid">
            <Content>
              <StyledSubtitle>Fidelização do seu cliente</StyledSubtitle>
              <Title>
                A&nbsp; Atrium se conecta em seus canais de venda para te
                aproximar do seu cliente.
              </Title>
              <Text>
                Potencializamos a experiência do seu cliente com a sua marca,
                uma vez que ele pode comprar uma prescrição pelo canal de vendas
                que preferir, sem precisar do papel.
              </Text>
              <StyledSingleInputForm buttonText="Saber mais" type="atrium" />
            </Content>
          </Grid>
        </Wrapper>
      )}
    </CustomVisibilitySensor>
  );
}
