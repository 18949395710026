import React from 'react';
import { SolucoesTopBanner } from 'components/Solucoes';
import {
  ScrollableWrapper,
  ImagesList,
  ListItem,
  ItemImage,
  ItemText
} from './styles';
import { px2rem } from 'src/util';

export default function AtriumTopBanner() {
  return (
    <SolucoesTopBanner
      image={require('assets/atrium-topo.svg')}
      imageStyle={`
        @media screen and (min-width: 998px) {
          right: 0;
          top: 0;
          width: ${(798 / 1920) * 100}%;
        }
      
        @media screen and (max-width: 997px) {
          bottom: 308px;
          height: 406px;
          right: -97px;
        }
      `}
      customStyle={`
        h2 {
          margin-bottom: ${px2rem(48)};
          
          span {
            display: inline-block;
          }
        }
        
        @media screen and (min-width: 998px) {
          > div {
            padding-top: ${(276 / 1920) * 100}%;
          }

          h2 {
            max-width: ${px2rem(580)};
          }
        }
        
        @media screen and (max-width: 997px) {
          h2 {
            margin-bottom: 370px;
            max-width: 280px;
          }
        }
      `}
      subtitle="Nexodata Atrium API"
      title={
        <>
          Sua estratégia de <span>omni-channel</span> conectada à prescrição
          eletrônica.
        </>
      }
    >
      <ScrollableWrapper>
        <ImagesList>
          <ListItem>
            <ItemImage
              src={require('assets/loja-fisica-icon.svg')}
              alt="Loja física"
            />
            <ItemText>Loja Física</ItemText>
          </ListItem>
          <ListItem>
            <ItemImage
              src={require('assets/e-commerce-icon.svg')}
              alt="E-commerce"
            />
            <ItemText>E-commerce</ItemText>
          </ListItem>
          <ListItem>
            <ItemImage src={require('assets/seu-app-icon.svg')} alt="Seu App" />
            <ItemText>Seu App</ItemText>
          </ListItem>
          <ListItem>
            <ItemImage
              src={require('assets/whatsapp-atrium-icon.svg')}
              alt="WhatsApp"
            />
            <ItemText>WhatsApp</ItemText>
          </ListItem>
          <ListItem>
            <ItemImage
              src={require('assets/televendas-icon.svg')}
              alt="Televendas"
            />
            <ItemText>Televendas</ItemText>
          </ListItem>
        </ImagesList>
      </ScrollableWrapper>
    </SolucoesTopBanner>
  );
}
